import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PreHeading from "../components/PreHeading";
import ArticleSection from "../components/ArticleSection";
import ArticlePage from "../components/ArticlePage";
import FooterJumbotron from "../components/FooterJumbotron";

const HowItWorks = () => (
  <Layout>
    <SEO title="About us" />
    <ArticlePage title={"About us"}>
      <ArticleSection className="text-center">
        <p>Whether you are on vacation or on a business trip, a delay, cancellation or another travel problem, this is always annoying. Unfortunately, airlines often fail to respond and generally refuse to pay the legal compensation. Lack of knowledge, expertise and time are the main reasons for passengers and companies to give up. For this reason, Yource, the parent company of, among others, Flight-delayed.com, was founded in 2010.</p>
        <PreHeading>Our mission</PreHeading>
        <h2>We are here to help</h2>
        <p>Started by helping travelers from within and outside Europe to claim compensation for delayed, canceled or overbooked flights, Yource has now also grown internationally into a specialist for both individual passengers and business services with complete customer contact solutions for travel organizations, insurers and companies mainly in the field of travel. We provide the most straight forward and easy-to-use service.</p>
      </ArticleSection>
      <FooterJumbotron noSkew>
        <h2>Yource is currently live in 9 countries and offers customer support in 8 different languages under one brand.</h2>
      </FooterJumbotron>
    </ArticlePage>
  </Layout>
)

export default HowItWorks
