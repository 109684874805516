import React from "react"

const PreHeading = ({ children }) => {
  return (
    <span className="block font-pt-sans font-semibold text-base md:text-xl mb-5 text-green uppercase">
      {children}
    </span>
  )
};

export default PreHeading;